import axios from "@/utils/axios";

export const list = (data) => {
  return axios.post("/api/v1/system-role/page", data);
};
export const add = (data) => {
  return axios.post("/api/v1/system-role", data);
};
export const update = (data) => {
  return axios.put("/api/v1/system-role", data);
};
export const updatePower = (id, data) => {
  return axios.put(`/api/v1/system-role/power/${id}`, data);
};
export const del = (id) => {
  return axios.delete(`/api/v1/system-role/${id}`);
};
export const getPower = (id) => {
  return axios.get(`/api/v1/system-role/power/${id}`);
};
export const get = (id) => {
  return axios.get(`/api/v1/system-role/${id}`);
};
export const select = (type) => {
  return axios.get(`/api/v1/system-role/select/${type}`);
};
export const menu = (roleId) => {
  return axios.get(`/api/v1/system-role/role/${roleId}`);
};
