import { getMenuId, setMenuId } from "@/utils/auth";

const getDefaultState = () => {
  return {
    menuId: getMenuId(),
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_MENUID: (state, menuId) => {
    setMenuId(menuId);
    state.menuId = menuId;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
