import { login } from "@/api/user";
import {
  getToken,
  setToken,
  removeToken,
  getUserName,
  setUserName,
  removeUserName,
  getRole,
  setRole,
  getId,
  setId,
} from "@/utils/auth";
import router from "@/router";

const getDefaultState = () => {
  return {
    token: getToken(),
    name: getUserName(),
    roleId: getRole(),
    dataId: getId(),
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_ROLE: (state, roleId) => {
    state.roleId = roleId;
  },
  SET_Id: (state, dataId) => {
    state.dataId = dataId;
  },
};

const actions = {
  // user login
  login({ commit }, UserName) {
    const { account, password } = UserName;
    return new Promise((resolve, reject) => {
      login({ account: account.trim(), password: password })
        .then((response) => {
          const data = response.data;
          commit("SET_TOKEN", data.loginToken);
          commit("SET_NAME", data.account);
          commit("SET_ROLE", data.roleId);
          commit("SET_Id", data.dataId);
          setToken(data.loginToken);
          setUserName(data.account);
          setRole(data.roleId);
          setId(data.dataId);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  logout({ commit }) {
    removeToken();
    removeUserName();
    commit("RESET_STATE");
    router.push("/login");
  },

  // remove token
  resetToken({ commit }) {
    removeToken();
    commit("RESET_STATE");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
