<template>
  <div class="app-container">
    <el-form ref="form" :model="pwdForm" label-width="120px">
      <el-form-item label="旧密码" prop="oldPassword" required>
        <el-input
          v-model="pwdForm.oldPassword"
          type="password"
          placeholder="旧密码"
        />
      </el-form-item>
      <el-form-item prop="newPassword" label="新密码" required>
        <el-input
          v-model="pwdForm.newPassword"
          type="password"
          placeholder="新密码"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('form')">确认</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    pwdForm: {},
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("okChange", this.pwdForm);
        } else {
          return false;
        }
      });
    },
    onCancel() {
      this.$emit("cancelChange", false);
    },
  },
};
</script>

<style scoped>
.line {
  text-align: center;
}
</style>
