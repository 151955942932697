import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";

import { getToken } from "@/utils/auth";

const service = axios.create({
  // baseURL: "http://192.168.16.110:8088",
  baseURL: "https://www.cyhqqyfw.com:8080",
  timeout: 5000,
});


service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers["x-token"] = getToken();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.hasOwnProperty("code") && res.code !== 1) {
      if (res.code === -2) {
        Message({
          message: "token失效,重新登陆",
          type: "warning",
          duration: 2 * 1000,
        });
        store.dispatch("user/logout");
        return res.msg;
      }
      Message({
        message: res.msg || "Error",
        type: "error",
        duration: 2 * 1000,
      });
      return res.msg;
    } else {
      return res;
    }
  },
  (error) => {
    Message({
      message: error.msg,
      type: "error",
      duration: 2 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
