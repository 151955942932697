<template>
  <div>
    <h4 v-if="!isCollapse">企业工单处理软件系统</h4>
    <h4 v-else style="width: 54px">平台</h4>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        router
        :default-active="activeMenu"
        :collapse="isCollapse"
        background-color="#304156"
        text-color="#bfcbd9"
        unique-opened
        active-text-color="#409EFF"
        :collapse-transition="false"
      >
        <template v-for="(item, index) in menuList">
          <div v-if="item.checked">
            <el-submenu
              ref="subMenu"
              :index="item.router"
              popper-append-to-body
              v-if="item.children&&item.children.length>0"
            >
              <template slot="title">
                <i class="sub-el-icon" :class="item.icon"></i>
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item
                v-for="(child, index) in item.children"
                :key="index"
                :index="child.router"
                @click="handleMenuClick(child.id)"
                v-if="child.checked && child.level == 1"
              >
                <i class="sub-el-icon" :class="child.icon"></i>
                <span>{{ child.name }}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item
              v-else
              :key="item.id"
              :index="item.router"
              @click="handleMenuClick(item.id)"
              class="submenu-title-noDropdown"
            >
              <i class="sub-el-icon" :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </el-menu-item> 
          </div>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as roleApi from "@/api/role";

export default {
  data() {
    return {
      menuList: [],
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
    activeMenu() {
      const route = this.$route.path;
      return route;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  created() {
    let roleId = this.$store.state.user.roleId;
    this.getMenu(roleId);
  },
  methods: {
    getMenu(roleId) {
      roleApi.menu(roleId).then((res) => {
        this.menuList = res.data;
      });
    },
    handleMenuClick(id) {
      this.$store.commit("menu/SET_MENUID", id);
    },
  },
};
</script>
<style>
h4 {
  width: 210px;
  text-align: center;
  color: #f4f4f5;
}
.main-container {
  min-height: 100%;
  transition: margin-left 0.28s;
  margin-left: 210px;
  position: relative;
}

.sidebar-container {
  transition: width 0.28s;
  width: 210px !important;
  background-color: #304156;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
}

.horizontal-collapse-transition {
  transition: 0s width ease-in-out, 0s padding-left ease-in-out,
    0s padding-right ease-in-out;
}

.scrollbar-wrapper {
  overflow-x: hidden !important;
}

.el-scrollbar__bar.is-vertical {
  right: 0px;
}

.el-scrollbar {
  height: 100%;
}

.el-scrollbar .has-logo .el-scrollbar {
  height: calc(100% - 50px);
}

.is-horizontal {
  display: none;
}

a {
  width: 100%;
  overflow: hidden;
}

.svg-icon {
  margin-right: 16px;
}

.sub-el-icon {
  margin-right: 12px;
  margin-left: -2px;
}

.el-menu {
  border: none;
  height: 100%;
  width: 100% !important;
}

.submenu-title-noDropdown:hover {
  background-color: #263445 !important;
}
.el-submenu__title:hover {
  background-color: #263445 !important;
}

.is-active > .el-submenu__title {
  color: #f4f4f5 !important;
}

.nest-menu .el-submenu > .el-submenu__title,
.el-submenu .el-menu-item {
  min-width: 210px !important;
  background-color: #1f2d3d !important;
}
.el-submenu .el-menu-item:hover {
  background-color: #001528 !important;
}

.hideSidebar .sidebar-container {
  width: 54px !important;
}

.hideSidebar .main-container {
  margin-left: 54px;
}

.hideSidebar .submenu-title-noDropdown {
  padding: 0 !important;
  position: relative;
}

.hideSidebar .el-tooltip {
  padding: 0 !important;
}

.hideSidebar .svg-icon {
  margin-left: 20px;
}

.hideSidebar .sub-el-icon {
  margin-left: 19px;
}

.hideSidebar .el-submenu {
  overflow: hidden;
}

.hideSidebar .el-submenu > .el-submenu__title {
  padding: 0 !important;
}

.hideSidebar .svg-icon {
  margin-left: 20px;
}

.hideSidebar .sub-el-icon {
  margin-left: 19px;
}

.hideSidebar .el-submenu__icon-arrow {
  display: none;
}
.hideSidebar .el-menu--collapse  span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}

.el-menu--collapse .el-menu .el-submenu {
  min-width: 210px !important;
}

.mobile .main-container {
  margin-left: 0px;
}

.sidebar-container {
  transition: transform 0.28s;
  width: 210px !important;
}

.sidebar-container .hideSidebar .sidebar-container {
  pointer-events: none;
  transition-duration: 0.3s;
  transform: translate3d(-210px, 0, 0);
}

.withoutAnimation .main-container,
.sidebar-container {
  transition: none;
}

.el-menu--vertical > .el-menu .svg-icon {
  margin-right: 16px;
}
.sub-el-icon {
  margin-right: 12px;
  margin-left: -2px;
}

.nest-menu .el-submenu > .el-submenu__title,
.el-menu-item :hover {
  background-color: #263445 !important;
}

.el-menu--popup {
  max-height: 100vh;
  overflow-y: auto;
}
</style>
