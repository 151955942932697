import axios from "@/utils/axios";

export const login = (data) => {
  return axios.post("/api/v1/system-user/login", data);
};
export const list = (data) => {
  return axios.post("/api/v1/system-user/page", data);
};
export const add = (data) => {
  return axios.post("/api/v1/system-user", data);
};
export const update = (data) => {
  return axios.put("/api/v1/system-user", data);
};
export const del = (id) => {
  return axios.delete(`/api/v1/system-user/${id}`);
};
export const get = (id) => {
  return axios.get(`/api/v1/system-user/${id}`);
};
export const resetPWD = (id) => {
  return axios.put(`/api/v1/system-user/reset-password/${id}`);
};
export const updatePWD = (data) => {
  return axios.put("/api/v1/system-user/update-password", data);
};
