import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "首页",
        component: () => import("@/views/main.vue"),
      },
    ],
  },
  {
    path: "/system",
    component: Layout,
    redirect: "noRedirect",
    name: "系统设置",
    children: [
      {
        path: "/system-user",
        name: "系统用户",
        component: () => import("@/views/system-user/index"),
      },
      {
        path: "/system-menu",
        name: "系统菜单",
        component: () => import("@/views/system-menu/index"),
      },
      {
        path: "/system-log",
        name: "操作日志",
        component: () => import("@/views/system-log/index"),
      },
      {
        path: "/system-role",
        name: "系统角色",
        component: () => import("@/views/system-role/index"),
      },
    ],
  },
  {
    path: "/basic",
    component: Layout,
    redirect: "noRedirect",
    name: "基础设置",
    children: [
      {
        path: "/basic-unit",
        name: "单位管理",
        component: () => import("@/views/basic-unit/index"),
      },
      {
        path: "/work-department",
        name: "单位账号",
        component: () => import("@/views/work-department/index.vue"),
      },
    ],
  },
  {
    path: "/work",
    component: Layout,
    redirect: "noRedirect",
    name: "业务管理",
    children: [

      {
        path: "/work-company",
        name: "企业管理",
        component: () => import("@/views/work-company/index.vue"),
      },
      {
        path: "/work-document",
        name: "企业工单",
        component: () => import("@/views/work-document/index"),
      },
      {
        path: "/work-type",
        name: "工单类型",
        component: () => import("@/views/work-type/index"),
      },
      {
        path: "/wxapp",
        name: "小程序设置",
        component: () => import("@/views/wxapp/index"),
      },
      {
        path: "/work-log",
        name: "走访记录",
        component: () => import("@/views/work-log/list.vue"),
      },
      {
        path: "/work-sign",
        name: "打卡记录",
        component: () => import("@/views/work-sign/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "登录",
    component: () => import("@/views/login.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
