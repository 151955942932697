import router from "@/router";
import store from "@/store";
import { Message } from "element-ui";
import { getToken, getUserName } from "@/utils/auth";

const whiteList = ["/login"];

router.beforeEach(async (to, from, next) => {
  const hasToken = getToken();
  if (hasToken) {
    if (to.path === "/login") {
      next({ path: "/" });
    } else {
      const hasGetUserName = store.getters.name;
      if (hasGetUserName) {
        next();
      } else {
        try {
          getUserName();
          next();
        } catch (error) {
          await store.dispatch("user/resetToken");
          Message.error(error || "Has Error");
          next("/login");
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/login");
    }
  }
});
