<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <span class="user-avatar">{{ name }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item divided @click.native="changePWD">
            <span style="display: block">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span style="display: block">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog title="修改密码" :visible.sync="changeModal">
      <change-pwd
        :pwdForm="pwdForm"
        @cancelChange="cancelChange"
        @okChange="okChange"
      ></change-pwd>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import changePwd from "@/views/changePwd.vue";
import * as userApi from "@/api/user";

export default {
  data() {
    return {
      changeModal: false,
      pwdForm: {
        oldPassword: "",
        newPassword: "",
      },
    };
  },
  components: {
    Breadcrumb,
    Hamburger,
    "change-pwd": changePwd,
  },
  computed: {
    ...mapGetters(["sidebar", "name"]),
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    logout() {
      this.$store.dispatch("user/logout");
    },
    changePWD() {
      this.changeModal = true;
      this.pwdForm = {
        oldPassword: "",
        newPassword: "",
      };
    },
    okChange(val) {
      this.pwdForm = val;
      userApi.updatePWD(this.pwdForm).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.$store.dispatch("user/logout");
        }
      });
    },
    cancelChange(val) {
      this.changeModal = val;
    },
  },
};
</script>

<style scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.hamburger-container {
  line-height: 46px;
  height: 100%;
  float: left;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;
}

.hamburger-container:hover {
  background: rgba(0, 0, 0, 0.025);
}

.breadcrumb-container {
  float: left;
}

.right-menu {
  float: right;
  height: 100%;
  line-height: 50px;
}

.right-menu:focus {
  outline: none;
}

.right-menu-item {
  display: inline-block;
  padding: 0 8px;
  height: 100%;
  font-size: 18px;
  color: #5a5e66;
  vertical-align: text-bottom;
}

.right-menu-item .hover-effect {
  cursor: pointer;
  transition: background 0.3s;
}

.hover-effect:hover {
  background: rgba(0, 0, 0, 0.025);
}

.avatar-container {
  margin-right: 30px;
}

.avatar-wrapper {
  position: relative;
}

.user-avatar {
  cursor: pointer;
  line-height: 50px;
  display: inline-block;
}

.el-icon-caret-bottom {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 20px;
  font-size: 12px;
}
</style>
