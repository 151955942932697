import Cookies from "js-cookie";

const TokenKey = "token";
const UserNameKey = "userName";
const RoleKey = "roleId";
const menuKey = "menuId";
const Id = "dataId";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function getId() {
  if (Cookies.get(Id) == 'null') {
    return JSON.parse(Cookies.get(Id));
  } else {
    return Cookies.get(Id);
  }
}

export function setId(dataId) {
  return Cookies.set(Id, dataId);
}

export function removeId() {
  return Cookies.remove(Id);
}
export function getUserName() {
  return Cookies.get(UserNameKey);
}

export function setUserName(usernamr) {
  return Cookies.set(UserNameKey, usernamr);
}

export function removeUserName() {
  return Cookies.remove(UserNameKey);
}
export function getRole() {
  return Cookies.get(RoleKey);
}

export function setRole(roleId) {
  return Cookies.set(RoleKey, roleId);
}

export function removeRole() {
  return Cookies.remove(RoleKey);
}
export function getMenuId() {
  return Cookies.get(menuKey);
}

export function setMenuId(menuId) {
  return Cookies.set(menuKey, menuId);
}

export function removeMenuId() {
  return Cookies.remove(menuKey);
}
